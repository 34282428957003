<template>
  <perfect-scrollbar
    :style="{
      width: clickIndex !== -1 ? '460px' : '320px',
      'max-height': getContainerHeight - 115 + 'px',
    }"
    class="dataPanelexpendablesWrapper flex justify-center">
    <div class="max-w-sm h-full" style="width: 320px">
      <p v-if="propertyData.chips && propertyData.chips.length > 0 && propertyData.chips[currentExpandable].title === 'Keine Daten vorhanden'" class="px-2 py-2">
        Keine Daten vorhanden
      </p>
      <v-expansion-panels
        v-if="propertyData.chips && propertyData.chips.length > 0  && propertyData.chips[currentExpandable].title !== 'Keine Daten vorhanden'"
        v-model="isOpen"
        mandatory
        variant="accordion">
        <v-expansion-panel
          v-for="(expandable, index) in propertyData.chips[currentExpandable]
            .items"
          :key="index"
          :eager="false"
          class="last:rounded-b-[8px]">
          <v-expansion-panel-title
            v-if="expandable.mevHeader"
            hide-actions
            :disabled="true">
            <div class="body-2 -ml-[4px]">
              {{ getActiveMevName.name }}
            </div>
          </v-expansion-panel-title>
          <v-expansion-panel-title
            v-if="expandable.netHeader"
            hide-actions
            :disabled="true">
            <div class="body-2 -ml-[4px]">
              {{ getPhaseName }}
            </div>
          </v-expansion-panel-title>
          <v-expansion-panel-title
            v-if="expandable.title"
            @click="clickIndex = -1">
            <div class="body-2 -ml-[4px]">
              {{ expandable.title }}
            </div>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-carousel
              v-if="expandable.items.filter((e) => e.panelItemType).length > 0"
              height="fit-content"
              class="carousel-custom bg-infra-highlight-25">
              <div
                v-for="item in expandable.items.filter((e) => e.panelItemType)"
                :key="item.title"
                class="bg-infra-highlight-25">
                <v-carousel-item>
                  <doughnut-chart
                    class="h-72 w-full"
                    :title="item.title"
                    :chart-data="chartData(item)"
                    :title-inside="item.titleInside"
                    :chart-unit="item.unit"
                    :title-custom-size="13" />
                </v-carousel-item>
              </div>
            </v-carousel>
            <div
              v-if="
                expandable.items.filter((e) => !e.panelItemType).length > 0
              ">
              <div
                v-for="(item, index) in expandable.items.filter(
                  (e) => !e.panelItemType
                )"
                :key="item.title"
                class="flex px-3 items-center text-black font-medium text-xs hover:bg-white relative"
                :class="expansionPanelTextCustomClass(item, index)">
                <panel-item-editable
                  v-if="typeof item.type !== 'undefined'"
                  :item="item"
                  :is-selected="clickIndex === index"
                  :index="index"
                  :click-index="clickIndex"
                  :is-editable="isEditable(item)"
                  @edit-item="editItem(item, $event)"
                  @set-index="clickIndex = -1"
                  @not-in-range="snackBar($event)" />
              </div>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </perfect-scrollbar>
</template>

<script>
import resizeUnitsMethod from '@/mixins/resizeUnitsMethod.vue';
import { mapGetters } from 'vuex';

export default {
  mixins: [resizeUnitsMethod],
  props: {
    propertyData: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      isOpen: 0,
      clickIndex: -1,
    };
  },
  computed: {
    // find active chip in propertyData.chips and return its index
    currentExpandable() {
      return this.propertyData.chips.findIndex((chip) => chip.active === true);
    },
    ...mapGetters('map', [
      'getFeatureIsEditable',
      'getActiveMevName',
      'getPhaseName',
    ]),
    ...mapGetters('map', ['getContainerHeight']),
  },
  watch: {
    currentExpandable() {
      this.isOpen = 0;
      this.clickIndex = -1;
    },
    isOpen() {
      this.resizeUnits();
    },
  },
  mounted() {
    this.resizeUnits();
  },
  methods: {
    chartData(item) {
      const data = [];
      item.value.forEach((e) => {
        data.push({
          color: e.color,
          name: e.name,
          value: item.multiply ? e.value * item.multiply : e.value,
        });
      });
      return data;
    },
    // item is only editable if it is defined so in the getter and field is not read only
    isEditable(item) {
      return this.getFeatureIsEditable.some((e) => e) && !item.read_only;
    },
    editItem(item, index) {
      if (this.isEditable(item)) {
        this.clickIndex = index;
      }
    },
    snackBar(event) {
      const message =
        event.max === 0
          ? `Bitte geben Sie einen Wert größer als ${event.min}  ein.`
          : `Bitte geben Sie einen Wert zwischen ${event.min} und ${event.max}  ein.`;
      this.$store.commit('layout/SHOWTOAST', {
        timeout: 1800,
        message,
        rounded: 'pill',
        color: '#FF6C00',
      });
    },
    expansionPanelTextCustomClass(item, index) {
      // set pointer events none more obvious
      if (this.clickIndex === index) {
        return !this.isEditable(item)
          ? 'bg-white z-10 box-shadow-custom pointer-events-none'
          : 'bg-white z-10 box-shadow-custom';
      } else {
        return !this.isEditable(item)
          ? 'bg-infra-highlight-25 box-shadow-custom-hover pointer-events-none'
          : 'bg-infra-highlight-25 cursor-pointer box-shadow-custom-hover';
      }
    },
  },
};
</script>

<style lang="scss">
.dataPanelexpendablesWrapper {
  border-radius: 0 0 8px 8px;

  > .ps__rail-y {
    display: none !important;
  }

  .v-input__details {
    display: none;
  }

  .box-shadow-custom {
    box-shadow: 0 1px 6px #00000029;
    padding-bottom: 1px;

    &:last-child {
      margin-bottom: -1px;
    }
  }

  .box-shadow-custom-hover {
    &:hover {
      box-shadow: 0 1px 6px #00000029;
      z-index: 10 !important;

      .singleItemcustom {
        margin-bottom: 1px;
        border-bottom: none !important;
      }
    }

    &:last-child {
      &:hover {
        margin-bottom: -1px;
      }

      .singleItemcustom {
        border-bottom: hidden !important;
      }
    }
  }

  .ps__rail-x {
    display: none !important;
  }

  .v-field__append-inner {
    padding-top: 0;
  }

  .v-expansion-panel-text__wrapper {
    @apply p-0;
  }

  .v-expansion-panel-title {
    @apply px-4 py-2;
    min-height: 50px !important;
  }

  .v-expansion-panel-title__overlay {
    opacity: 0;
  }

  .v-expansion-panel-title {
    min-height: 44px !important;
  }

  .v-expansion-panel-title--active {
    background-color: white;
  }

  .v-expansion-panel-title__icon {
    .mdi-chevron-up {
      background-image: url('@/assets/svg/minus-blue.svg');
      background-repeat: no-repeat;
      //background-size: 16px;
      background-position: center center;
    }

    .mdi-chevron-up:before {
      display: none;
    }

    .mdi-chevron-down {
      background-image: url('@/assets/svg/plus-blue.svg');
      background-repeat: no-repeat;
      //background-size: 10px;
      background-position: center center;
    }

    .mdi-chevron-down:before {
      display: none;
    }
  }

  .carousel-custom {
    .v-window__container {
      height: unset !important;
      overflow: hidden;
    }

    .v-window__controls {
      .v-window__left,
      .v-window__right {
        margin-top: auto;
        z-index: 10;
        box-shadow: none;
        width: 40px;
        height: 55px;

        .v-btn__content {
          background: white;
          border-radius: 6px;
          height: 24px;
          width: 24px;

          &:hover {
            @apply bg-infra-highlight-100;
          }
        }
      }
    }

    .v-btn__content {
      .mdi-circle {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #0692bc;
        opacity: 30%;

        &:before {
          display: none;
        }
      }

      .mdi-chevron-left:before,
      .mdi-chevron-right:before {
        display: none;
      }

      .mdi-chevron-left {
        background-image: url('@/assets/svg/arrow-left-blue.svg');
        background-repeat: no-repeat;
        background-size: 16px;
        background-position: center center;
      }

      .mdi-chevron-right {
        background-image: url('@/assets/svg/arrow-right-blue.svg');
        background-repeat: no-repeat;
        background-size: 16px;
        background-position: center center;
      }
    }

    .v-ripple__container {
      display: none;
    }

    .v-btn--active > .v-btn__overlay,
    .v-btn[aria-haspopup='menu'][aria-expanded='true'] > .v-btn__overlay {
      opacity: 0;
    }

    .v-carousel__controls {
      background: transparent;
      height: 55px;
      left: 50% !important;
      transform: translateX(-50%) !important;

      .v-btn {
        width: 16px;
        height: 16px;

        &.v-btn--active {
          .mdi-circle {
            opacity: 100%;
          }
        }
      }
    }
  }
}
</style>

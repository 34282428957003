import buildings from './buildings';
import builtt from './builtt';
import tickets from './tickets';
import infrastructureDraft from './infrastructureDraft';
import viewingLevel from './viewingLevel';
import mev from './mev';
import fundingapplication from "./fundingapplication";
import tenders from './tenders';
import construction from './construction';
import shortcuts from './shortcuts';
import expansionplanning from './expansionplanning';

export default [
  {
    title: 'Kartenansicht',
    sections: [
      {
        title: 'Adresskulisse',
        items: [buildings, construction, expansionplanning],
      },
      {
        title: 'Infrastruktur',
        items: [infrastructureDraft],
      },
      {
        title: 'Auswertung',
        items: [mev, fundingapplication, tenders, builtt, tickets, viewingLevel],
      },
      {
        title: 'Shortcuts',
        items: shortcuts,
      },
    ],
  },
];

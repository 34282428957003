<template>
  <div class="px-4">
    <DropDownMultipleGroupedAdvSearch
      :small="true"
      :max-height-dropdown="200"
      placeholder="Filter nach Antrag"
      class="py-2 bg-infra-highlight-25"
      :items="applicationNames"
      item-type-all="Anträge"
      data-key="name"
      value-key="value"
      @selected-items="applicationsSelected"/>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import InputEl
  from "../../../../components/storybook/src/stories/input/InputEl.vue";
import ButtonEl
  from "../../../../components/storybook/src/stories/button/ButtonEl.vue";
import axios from "../../../../utils/axiosHelper";
import DropDownMultipleGroupedAdvSearch
  from "./DropDownMultipleGroupedAdvSearch.vue";
import {
  LAYER_KEY__FUNDINGAPPLICATION_REF
} from "../../../../configs/layers/fundingapplication";

export default {
  components: {DropDownMultipleGroupedAdvSearch, ButtonEl, InputEl},
  props: {
    menuItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      refSearchValue: "",
      applicationNames: [],
      selectedApplications: [],
    }
  },
  computed: {
    ...mapGetters('map', ['getSwitchStates']),
  },
  mounted() {
    axios({
      url: '/api/funding/fundingapplication/application_names/',
      method: 'GET',
    })
    .then((resp) => {
      const { result } = resp.data;
      this.applicationNames = [{value: "Alle", name: "Alle", checked: true, items: result.map(
        (r) => {r.checked = true; return r}
      )}]
    })
    .catch((err) => {
      console.log(err);
    });
  },
  methods: {
    getActiveRefs(itemsData) {
      let refs = [];
      for (let item of itemsData) {
        // if item is lot and is checked, add it to result array
        if (item.checked) {
          refs.push(item.value);
        }
        // if item has children, look for lots there
        if (
          'items' in item &&
          Array.isArray(item.items) &&
          item.items.length > 0
        ) {
          refs = [...refs, ...this.getActiveRefs(item.items)];
        }
      }
      return refs;
    },
    applicationsSelected(itemsData, autoTrigger) {
      const layerKeys = [
        LAYER_KEY__FUNDINGAPPLICATION_REF
      ];
      let filter = null;
      // if all have been selected, simply send true
      if (itemsData.length && itemsData[0].checked) {
        filter = ['has', 'reference_number'];
      } else {
        const activeRefs = this.getActiveRefs(itemsData);
        // if no refs have been selected, send false
        if (activeRefs.length === 0) {
          filter = ['boolean', false];
        }
        // otherwise map refs to mapbox filter syntax
        else {
          filter = [
            'any',
            ...activeRefs.map((ref) => {
              return ['==', ['get', 'reference_number'], ref];
            }),
          ];
        }
      }
      this.$store.commit('map/SET_ADDITIONAL_FILTERS', {
        filter,
        layerKeys,
      });
    },
  },
};
</script>

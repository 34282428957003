import buildings from './buildings';
import builtt from './builtt';
import tickets from './tickets';
import infrastructureDraft from './infrastructureDraft';
import viewingLevel from './viewingLevel';
import mev from './mev';
import tenders from './tenders';
import fundingapplication from "./fundingapplication";
import construction from './construction';
import expansionplanning from './expansionplanning';

export default {
  ...viewingLevel,
  ...buildings,
  ...builtt,
  ...tickets,
  ...infrastructureDraft,
  ...mev,
  ...fundingapplication,
  ...tenders,
  ...construction,
  ...expansionplanning,
};

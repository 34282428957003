export const LAYER_SOURCE__FUNDINGAPPLICATION = 'fundingapplicationlayerdatapoint'

export const LAYER_KEY__FUNDINGAPPLICATION = 'fundingapplication'
export const LAYER_KEY__FUNDINGAPPLICATION_REF = 'fundingapplicationref'
export const LAYER_KEY__FUNDINGAPPLICATION_CHANGE = 'fundingapplicationchange'


const getMEVSidebarChip = (year, active = false) => {
  let title = "RL 1.0\n(2021)"
  if (year === "2023") {
    title = "RL 2.0\n(2023)"
  }
  if (year === "2024") {
    title = "RL 2.0\n(2024)"
  }
  if (year === "2024_1") {
    title = "RL 2.0\n(2024 Wdh.)"
  }
  return {
    title,
    active,
    items: [
      {
        title: 'Gebäude',
        active: true,
        items: [
          {
            key: `mev_class_${year}`,
            unit: '',
            value: '-',
          },
          {
            key: `socio_economic_focus_${year}`,
            unit: '',
            value: '-',
          },
          {
            key: `expansion_${year}`,
            unit: '',
            value: '-',
          },
          {
            key: `homes_passed_technology_${year}`,
            unit: '',
            value: '-',
          },
          {
            key: `rejections_${year}`,
            unit: '',
            value: '0',
          },
        ],
      },
      {
        title: 'Versorgung (heute)',
        active: false,
        items: [
          {
            key: `status_quo_company_${year}`,
            unit: '',
            value: '-',
          },
          {
            key: `status_quo_technology_${year}`,
            unit: '',
            value: '-',
          },
          {
            key: `status_quo_download_${year}`,
            unit: ' MBits/s',
            value: '0',
            decimal: 0,
          },
          {
            key: `status_quo_upload_${year}`,
            unit: ' MBits/s',
            value: '0',
            decimal: 0,
          },
        ],
      },
      {
        title: 'Versorgung (geplant)',
        active: false,
        items: [
          {
            key: `plan_company_${year}`,
            unit: '',
            value: '-',
          },
          {
            key: `plan_technology_${year}`,
            unit: '',
            value: '-',
          },
          {
            key: `plan_download_${year}`,
            unit: ' MBits/s',
            value: '0',
            decimal: 0,
          },
          {
            key: `plan_upload_${year}`,
            unit: ' MBits/s',
            value: '0',
            decimal: 0,
          },
        ],
      },
    ]
  }
}

export default {
  [LAYER_KEY__FUNDINGAPPLICATION]: {
    layoutState: 'default',
    layerConfig: {
      app_name: 'funding',
      router_name: 'fundingapplication',
      key_name: 'pk',
      applyGemeindeFilter: true,
      type: 'point',
      id: LAYER_KEY__FUNDINGAPPLICATION,
      sourceLayer: LAYER_SOURCE__FUNDINGAPPLICATION,
      source: LAYER_KEY__FUNDINGAPPLICATION,
      visibility: 'none',
      hoverPointer: true,
    },
    onClick(click, feature, data) {
      const featureIdToHighlight = feature.properties.pk
      const refToHighlight = feature.properties.reference_number;
      click.map.setPaintProperty(LAYER_KEY__FUNDINGAPPLICATION, 'circle-color', [
        'case',
        ['==', ['get', 'pk'], featureIdToHighlight],
        "#ff1500",
        ...(refToHighlight ? [['==', ['get', 'reference_number'], refToHighlight],
        "#ff9900"] : []),
        ['==', ['get', 'richtlinie'], '2022'],
        '#ffff00',
        ['==', ['get', 'richtlinie'], '2023'],
        '#4a7720',
        ['==', ['get', 'richtlinie'], '2024'],
        '#c94f51',
        ['==', ['get', 'richtlinie'], '2024_1'],
        '#422eda',
        '#7f7f7f'
      ]);
      click.map.setPaintProperty(LAYER_KEY__FUNDINGAPPLICATION, 'circle-stroke-width', [
        'case',
        ['==', ['get', 'pk'], featureIdToHighlight],
        2,
        ...(refToHighlight ? [['==', ['get', 'reference_number'], refToHighlight],
        1] : []),
        0.1
      ]);
    },
    onDeactivate(map) {
      map.setPaintProperty(LAYER_KEY__FUNDINGAPPLICATION, 'circle-color', [
        'case',
        ['==', ['get', 'richtlinie'], '2022'],
        '#ffff00',
        ['==', ['get', 'richtlinie'], '2023'],
        '#4a7720',
        ['==', ['get', 'richtlinie'], '2024'],
        '#c94f51',
        ['==', ['get', 'richtlinie'], '2024_1'],
        '#422eda',
        '#7f7f7f'
      ]);
      map.setPaintProperty(LAYER_KEY__FUNDINGAPPLICATION, 'circle-stroke-width', 0.1);
    },
    style: {
      default: {
        'circle-color': [
          'case',
          ['==', ['get', 'richtlinie'], '2022'],
          '#ffff00',
          ['==', ['get', 'richtlinie'], '2023'],
          '#4a7720',
          ['==', ['get', 'richtlinie'], '2024'],
          '#c94f51',
          ['==', ['get', 'richtlinie'], '2024_1'],
          '#422eda',
          '#7f7f7f'
        ],
        'circle-stroke-width': 0.1,
        'circle-radius': {
          stops: [
            [1, 0],
            [9, 2],
            [12, 4],
          ],
        },
        'circle-stroke-color': '#000000',
      },
    },
    legend: {
      default: {
        name: 'Förderanträge',
        unit: '',
        items: [
          {
            color: '#422eda',
            name: 'WFP',
            icon: 'mdi-circle',
          },
          {
            color: '#ffff00',
            name: 'Gigabit 1.0 (2021)',
            icon: 'mdi-circle',
          },
          {
            color: '#4a7720',
            name: 'Gigabit 2.0 (2023)',
            icon: 'mdi-circle',
          },
          {
            color: '#c94f51',
            name: 'Gigabit 2.0 (2024)',
            icon: 'mdi-circle',
          },
          {
            color: '#7f7f7f',
            name: 'Nicht förderfähig',
            icon: 'mdi-circle',
          },
        ],
      },
    },
    sidebar: {
      name: 'Adresse (Förderantrag)',
      subtitles: [{ key: 'uuid' }, { key: 'addresses' }, { key: 'reference_number' }, { key: 'richtlinie_text' }],
      chips: [
        getMEVSidebarChip("2022", true),
        getMEVSidebarChip("2023"),
        getMEVSidebarChip("2024"),
        getMEVSidebarChip("2024_1"),
      ],
    },
  },
  [LAYER_KEY__FUNDINGAPPLICATION_REF]: {
    layoutState: 'default',
    layerConfig: {
      app_name: 'funding',
      router_name: 'fundingapplication',
      key_name: 'pk',
      applyGemeindeFilter: true,
      type: 'point',
      id: LAYER_KEY__FUNDINGAPPLICATION_REF,
      sourceLayer: LAYER_SOURCE__FUNDINGAPPLICATION,
      source: LAYER_KEY__FUNDINGAPPLICATION_REF,
      visibility: 'none',
      hoverPointer: true,
    },
    style: {
      default: {
        circleColor: "#7f7f7f",
        circleStrokeWidth: 0.1,
        circleRadius: {
          stops: [
            [1, 0],
            [9, 2],
            [12, 4],
          ],
        },
        circleStrokeColor: '#000000',
      },
    },
    legend: {
      default: {
        name: 'Förderanträge',
        unit: '',
        items: [
          {
            color: '#7f7f7f',
            name: 'Förderantrag',
            icon: 'mdi-circle',
          },
        ],
      },
    },
    onClick(click, feature, data) {
      const featureIdToHighlight = feature.properties.pk
      const refToHighlight = feature.properties.reference_number;
      click.map.setPaintProperty(LAYER_KEY__FUNDINGAPPLICATION_REF, 'circle-color', [
        'case',
        ['==', ['get', 'pk'], featureIdToHighlight],
        "#ff1500",
        ...(refToHighlight ? [['==', ['get', 'reference_number'], refToHighlight],
        "#ff9900"] : []),
        '#7f7f7f'
      ]);
      click.map.setPaintProperty(LAYER_KEY__FUNDINGAPPLICATION_REF, 'circle-stroke-width', [
        'case',
        ['==', ['get', 'pk'], featureIdToHighlight],
        2,
        ...(refToHighlight ? [['==', ['get', 'reference_number'], refToHighlight],
        1] : []),
        0.1
      ]);
    },
    onDeactivate(map) {
      map.setPaintProperty(LAYER_KEY__FUNDINGAPPLICATION_REF, 'circle-color', "#7f7f7f");
      map.setPaintProperty(LAYER_KEY__FUNDINGAPPLICATION_REF, 'circle-stroke-width', 0.1);
    },
    sidebar: {
      name: 'Adresse (Förderantrag)',
      subtitles: [{ key: 'uuid' }, { key: 'addresses' }, { key: 'reference_number' }, { key: 'richtlinie_text' }],
      chips: [
        getMEVSidebarChip("2022", true),
        getMEVSidebarChip("2023"),
        getMEVSidebarChip("2024"),
        getMEVSidebarChip("2024_1"),
      ],
    },
  },
  [LAYER_KEY__FUNDINGAPPLICATION_CHANGE]: {
    layoutState: 'default',
    layerConfig: {
      app_name: 'funding',
      router_name: 'fundingapplication',
      key_name: 'pk',
      applyGemeindeFilter: true,
      type: 'point',
      id: LAYER_KEY__FUNDINGAPPLICATION_CHANGE,
      sourceLayer: LAYER_SOURCE__FUNDINGAPPLICATION,
      source: LAYER_KEY__FUNDINGAPPLICATION_CHANGE,
      visibility: 'none',
      hoverPointer: true,
    },
    style: {
      default: {
        circleColor: [
          'case',
          ['boolean', ['feature-state', 'clicked'], false],
          '#5c0000',
          ['==', ['get', 'mev_diff'], 'MEV nicht wiederholt'],
          '#cfcfcf',
          ['==', ['get', 'mev_diff'], 'Adresse neu'],
          '#0533f5',
          ['==', ['get', 'mev_diff'], 'keine Veränderung'],
          '#8d8d8d',
          ['==', ['get', 'mev_diff'], 'HGF zu DGF'],
          '#f3f241',
          ['==', ['get', 'mev_diff'], 'neu förderfähig'],
          '#13bff2',
          ['==', ['get', 'mev_diff'], 'nicht mehr förderfähig (Ausbauankündigung)'],
          '#f48e0b',
          ['==', ['get', 'mev_diff'], 'nicht mehr förderfähig'],
          '#f42908',
          '#000000'
        ],
        circleStrokeWidth: 0.1,
        circleRadius: {
          stops: [
            [1, 0],
            [9, 2],
            [12, 4],
          ],
        },
        circleStrokeColor: '#000000',
      },
    },
    legend: {
      default: {
        name: 'Förderanträge',
        unit: '',
        items: [
          {
            color: '#0533f5',
            name: 'Adresse neu',
            icon: 'mdi-circle',
          },
          {
            color: '#f3f241',
            name: 'HGF zu DGF',
            icon: 'mdi-circle',
          },
          {
            color: '#13bff2',
            name: 'Neu förderfähig',
            icon: 'mdi-circle',
          },
          {
            color: '#8d8d8d',
            name: 'Keine Veränderung',
            icon: 'mdi-circle',
          },
          {
            color: '#f42908',
            name: 'Nicht mehr förderfähig',
            icon: 'mdi-circle',
          },
          {
            color: '#f48e0b',
            name: 'Nicht mehr förderfähig (Ausbauankündigung)',
            icon: 'mdi-circle',
          },
          {
            color: '#d0d0d0',
            name: 'MEV nicht wiederholt',
            icon: 'mdi-circle',
          },
        ],
      },
    },
    sidebar: {
      name: 'Adresse (Förderantrag)',
      subtitles: [{ key: 'uuid' }, { key: 'addresses' }, { key: 'reference_number' }, { key: 'richtlinie_text' }],
      chips: [
        getMEVSidebarChip("2022", true),
        getMEVSidebarChip("2023"),
        getMEVSidebarChip("2024"),
        getMEVSidebarChip("2024_1"),
      ],
    },
  }
}

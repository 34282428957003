import {
  LAYER_KEY__FUNDINGAPPLICATION,
  LAYER_KEY__FUNDINGAPPLICATION_CHANGE, LAYER_KEY__FUNDINGAPPLICATION_REF
} from "../layers/fundingapplication";

export const SWITCH_ID__FUNDINGAPPLICATION_RL = 'LC_FUNDINGAPPLICATION_RL';
export const SWITCH_ID__FUNDINGAPPLICATION_REF = 'LC_FUNDINGAPPLICATION_REF';
export const SWITCH_ID__FUNDINGAPPLICATION_CHANGE_MEV = 'LC_FUNDINGAPPLICATION_CHANGE_MEV';


export default {
  title: 'Förderanträge',
  modelNameForCASL: 'networkdistributor',
  items: [
    {
      title: 'Förderrichtlinie',
      modelNameForCASL: 'networkdistributor',
      onLayers: [LAYER_KEY__FUNDINGAPPLICATION],
      switchId: SWITCH_ID__FUNDINGAPPLICATION_RL,
      itemClass: 'switch',
      radioGroups: [
        {
          visible: false,
          activated: 0,
          radios: [
            {
              title: 'Filter ausblenden',
              layerKeys: [
                LAYER_KEY__FUNDINGAPPLICATION,
              ],
              filter: 'reset',
            },
            {
              title: 'WFP',
              layerKeys: [
                LAYER_KEY__FUNDINGAPPLICATION,
              ],
              filter: ['==', ['get', 'richtlinie'], 'WFP'],
            },
            {
              title: 'Gigabit 1.0',
              layerKeys: [
                LAYER_KEY__FUNDINGAPPLICATION,
              ],
              filter: ['==', ['get', 'richtlinie'], '2022'],
            },
            {
              title: 'Gigabit 2.0 (2023)',
              layerKeys: [
                LAYER_KEY__FUNDINGAPPLICATION,
              ],
              filter: ['==', ['get', 'richtlinie'], '2023'],
            },
            {
              title: 'Gigabit 2.0 (2024)',
              layerKeys: [
                LAYER_KEY__FUNDINGAPPLICATION,
              ],
              filter: ['==', ['get', 'richtlinie'], '2024'],
            },
          ],
        },
      ],
      turnOffSwitches: [SWITCH_ID__FUNDINGAPPLICATION_REF, SWITCH_ID__FUNDINGAPPLICATION_CHANGE_MEV]
    },
    {
      title: 'Aktenzeichen',
      modelNameForCASL: 'networkdistributor',
      onLayers: [LAYER_KEY__FUNDINGAPPLICATION_REF],
      switchId: SWITCH_ID__FUNDINGAPPLICATION_REF,
      itemClass: 'switch',
      refSearch: true,
      turnOffSwitches: [SWITCH_ID__FUNDINGAPPLICATION_RL, SWITCH_ID__FUNDINGAPPLICATION_CHANGE_MEV]
    },
    {
      title: 'Veränderung MEV-Wdh.',
      modelNameForCASL: 'networkdistributor',
      onLayers: [LAYER_KEY__FUNDINGAPPLICATION_CHANGE],
      switchId: SWITCH_ID__FUNDINGAPPLICATION_CHANGE_MEV,
      itemClass: 'switch',
      radioGroups: [
        {
          visible: false,
          activated: 0,
          radios: [
            {
              title: 'Filter ausblenden',
              layerKeys: [
                LAYER_KEY__FUNDINGAPPLICATION_CHANGE,
              ],
              filter: 'reset',
            },
            {
              title: 'Adresse neu',
              layerKeys: [
                LAYER_KEY__FUNDINGAPPLICATION_CHANGE,
              ],
              filter: ['==', ['get', 'mev_diff'], 'Adresse neu'],
            },
            {
              title: 'HGF zu DGF',
              layerKeys: [
                LAYER_KEY__FUNDINGAPPLICATION_CHANGE,
              ],
              filter: ['==', ['get', 'mev_diff'], 'HGF zu DGF'],
            },
            {
              title: 'Neu förderfähig',
              layerKeys: [
                LAYER_KEY__FUNDINGAPPLICATION_CHANGE,
              ],
              filter: ['==', ['get', 'mev_diff'], 'neu förderfähig'],
            },
            {
              title: 'Keine Veränderung',
              layerKeys: [
                LAYER_KEY__FUNDINGAPPLICATION_CHANGE,
              ],
              filter: ['==', ['get', 'mev_diff'], 'keine Veränderung'],
            },
            {
              title: 'Nicht mehr förderfähig',
              layerKeys: [
                LAYER_KEY__FUNDINGAPPLICATION_CHANGE,
              ],
              filter: ['==', ['get', 'mev_diff'], 'nicht mehr förderfähig'],
            },
            {
              title: 'Nicht mehr förderfähig (Ausbauankündigung)',
              layerKeys: [
                LAYER_KEY__FUNDINGAPPLICATION_CHANGE,
              ],
              filter: ['==', ['get', 'mev_diff'], 'nicht mehr förderfähig (Ausbauankündigung)'],
            },
            {
              title: 'MEV nicht wiederholt',
              layerKeys: [
                LAYER_KEY__FUNDINGAPPLICATION_CHANGE,
              ],
              filter: ['==', ['get', 'mev_diff'], 'MEV nicht wiederholt'],
            },
          ],
        },
      ],
      turnOffSwitches: [SWITCH_ID__FUNDINGAPPLICATION_RL, SWITCH_ID__FUNDINGAPPLICATION_REF]
    },
  ],
};


import {
  LAYER_KEY__VIEWING_LEVEL_LANDKREIS,
  LAYER_KEY__VIEWING_LEVEL_LANDKREIS_BORDER,
} from '../configs/layers/viewingLevel';

const layerHierarchy = {
  heat: [],
  poly: [],
  line: [],
  fill: [],
  circle: [],
  symbol: [],
};

const landkreisLayerKeys = [
  LAYER_KEY__VIEWING_LEVEL_LANDKREIS,
  LAYER_KEY__VIEWING_LEVEL_LANDKREIS_BORDER,
];
let landkreisPermission = [];

export function setLayerHierarchy(map) {
  const layers = Object.values(layerHierarchy);
  const keys = Object.keys(layerHierarchy);
  for (let i = 0; i < layers.length; i += 1) {
    layers[i].forEach((layer) => {
      map.moveLayer(layer);
    });
    const drawLayers = map
      .getStyle()
      .layers.filter((l) => l.id.startsWith('gl-draw') && l.type === keys[i]);
    drawLayers.forEach((layer) => {
      map.moveLayer(layer.id);
    });
  }
  map.getStyle().layers.forEach((e) => {
    if (e.id.split('-').includes('label')) map.moveLayer(e.id);
  });
}

function getTileServerUrl(sourceLayer) {
  return `${window.TILESERVER_URL}/data/${sourceLayer}/{z}/{x}/{y}.pbf`;
}

function addIcons(config, map) {
  if (config.icons) {
    config.icons.forEach((icon) => {
      map.loadImage(icon.path, (error, image) => {
        if (error) throw error;
        map.addImage(icon.name, image);
      });
    });
  }
}

function addPolygon(config, map, styling) {
  if (config.type === 'polygon') {
    if (config.subType === 'fillExtrusion') {
      layerHierarchy.fill.push(config.id);
      map.U.addFillExtrusion(config.id, config.source, {
        visibility: config.visibility,
        'fill-extrusion-color': styling.fillExtrusionColor,
        'fill-extrusion-height': styling.fillExtrusionHeight,
        'fill-extrusion-opacity': styling.fillExtrusionOpacity,
        ...(config.sourceLayer && { 'source-layer': config.sourceLayer }),
        ...(config.filter && { filter: config.filter }),
      });
    } else {
      layerHierarchy.poly.push(config.id);
      map.U.addFill(config.id, config.source, {
        visibility: config.visibility,
        fillOutlineColor: styling.fillOutlineColor || '#000000',
        fillColor: styling.fillColor,
        fillOpacity: styling.fillOpacity,
        ...(config.sourceLayer && { 'source-layer': config.sourceLayer }),
        ...(config.filter && { filter: config.filter }),
      });
    }
  }
}

function addPoint(config, map, styling) {
  if (config.type === 'point') {
    layerHierarchy.circle.push(config.id);
    map.U.addCircle(config.id, config.source, {
      visibility: config.visibility,
      paint: {
        'circle-color': styling.circleColor || styling['circle-color'],
        'circle-stroke-width': styling.circleStrokeWidth || styling['circle-stroke-width'],
        'circle-stroke-color': styling.circleStrokeColor || styling['circle-stroke-color'],
        'circle-radius': styling.circleRadius || styling['circle-radius'],
      },
      ...(config.sourceLayer && { 'source-layer': config.sourceLayer }),
      ...(config.filter && { filter: config.filter }),
    });
  }
}

function addLine(config, map, styling) {
  if (config.type === 'line') {
    layerHierarchy.line.push(config.id);
    map.U.addLine(config.id, config.source, {
      visibility: config.visibility,
      paint: {
        'line-color': styling.lineColor,
        'line-width': styling.lineWidth,
      },
      ...(config.sourceLayer && { 'source-layer': config.sourceLayer }),
      ...(config.filter && { filter: config.filter }),
    });
  }
}

function addSymbol(config, map, styling) {
  if (config.type === 'symbol') {
    layerHierarchy.symbol.push(config.id);
    map.U.addSymbol(config.id, config.source, {
      visibility: config.visibility,
      layout: styling.layout,
      ...(config.sourceLayer && { 'source-layer': config.sourceLayer }),
      ...(config.filter && { filter: config.filter }),
    });
  }
}

function addHeatmap(config, map, styling) {
  if (config.type === 'heatmap') {
    layerHierarchy.heat.push(config.id);
    map.U.addHeatmapLayer(config.id, config.source, {
      visibility: config.visibility,
      paint: {
        'heatmap-weight': styling.weight,
        'heatmap-radius': [
          'interpolate',
          ['linear'],
          ['zoom'],
          0,
          1,
          7,
          10,
          12,
          15,
        ],
        'heatmap-color': styling.color,
      },
      ...(config.sourceLayer && { 'source-layer': config.sourceLayer }),
      ...(config.filter && { filter: config.filter }),
    });
  }
}

export function createLayer(map, data, setGeoJsonData) {
  for (const layer in data.layer) {
    const item = data.layer[layer];
    const config = item.layerConfig;
    const styling = item.style.default;

    // costum filter for landkreise
    if (landkreisLayerKeys.includes(config.id)) {
      landkreisPermission = data.$store.state.auth.permittedLandkreisIds;
      config.filter = ['in', 'pk', ...landkreisPermission];
    }

    if (!map.getSource(config.source) && !config.geoJSONSource) {
      map.U.addVector(config.source, getTileServerUrl(config.sourceLayer));
    }
    if (!map.getSource(config.source) && config.geoJSONSource) {
      map.U.addGeoJSON(config.source);
      if (config.dataSourceFromStore) {
        setGeoJsonData(
          config.source,
          data.$store.getters[config.dataSourceFromStore]
        );
      }
    }
    addIcons(config, map);
    addPolygon(config, map, styling);
    addPoint(config, map, styling);
    addLine(config, map, styling);
    addSymbol(config, map, styling);
    addHeatmap(config, map, styling);
  }
  setLayerHierarchy(map);
}
